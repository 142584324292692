import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import YearProduct from "../../components/home/YearProduct/YearProduct";

const Home = () => {
  // const [products, setProducts] = useState([]);
  // useEffect(() => {
  //   fetchProducts();
  // }, []); // Empty dependency array to run the effect once on mount
  // const fetchProducts = async () => {
  //   try {
  //     const response = await api.get("/product/list"); // Adjust the API endpoint as needed
  //     setProducts(response.data); // Store the fetched products in the state
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //     // Handle error accordingly, maybe set an error state
  //   }
  // };
  // console.log("FROM GOMW")
  return (
    <div className='w-full mx-auto'>
      <br />

      <Banner />
      <br />

      <BannerBottom />
      <div className='max-w-container mx-auto px-4 gap-4'>
        {/* <Sale /> */}
        <br />
        <NewArrivals />
        <BestSellers />
        <YearProduct />
        <SpecialOffers />
      </div>
    </div>
  );
};

export default Home;
