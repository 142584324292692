import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { FaSearch, FaUser, FaCaretDown, FaShoppingCart } from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { paginationItems } from "../../../constants";
import api from "../../../utils/axiosInstance";

const HeaderBottom = () => {
  const products = useSelector((state) => state.orebiReducer.products);
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();
  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current.contains(e.target)) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  }, [show, ref]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    // Fetch filtered products from the backend
    const fetchFilteredProducts = async () => {
      try {
        const response = await api.get(`/product/list?name=${searchQuery}`);
        setFilteredProducts(response.data); // Assuming the response contains the filtered products
      } catch (error) {
        console.error("Error fetching filtered products:", error);
        // Handle error state if necessary
      }
    };

    if (searchQuery) {
      fetchFilteredProducts();
    } else {
      setFilteredProducts([]); // Clear results when search query is empty
    }
  }, [searchQuery]);

  return (
    <div className='w-full bg-[#F5F5F3] relative'>
      <div className='max-w-container mx-auto'>
        <Flex className='flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24'>
          <div className='relative w-full lg:w-[600px] h-[50px] text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl'>
            <input
              className='flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]'
              type='text'
              onChange={handleSearch}
              value={searchQuery}
              placeholder='Search your products here'
            />
            <FaSearch className='w-5 h-5' />
            {searchQuery && (
              <div
                className={`w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}
              >
                {searchQuery &&
                  filteredProducts.map((item) => (
                    <div
                      onClick={() =>
                        navigate(`/product/${item.id}`, {
                          state: { item: item },
                        }) &
                        setShowSearchBar(true) &
                        setSearchQuery("")
                      }
                      key={item._id}
                      className='max-w-[700px] h-32  overflow-hidden bg-white shadow-md rounded-md mb-4 flex items-center gap-4 p-4 hover:shadow-lg transition-shadow duration-300 cursor-pointer'
                    >
                      <img className='w-[70px] h-[70px] object-cover rounded-md' src={item.image} alt='productImg' />
                      <div className='flex flex-col gap-2 flex-1'>
                        <p className='font-semibold text-xl text-gray-800'>{item.name}</p>
                        <p className='text-sm text-gray-600 line-clamp-2'>
                          {item.description.length > 100 ? `${item.description.slice(0, 100)}...` : item.description}
                        </p>
                        <p className='text-lg text-gray-800 font-medium'>
                          Price: <span className='text-primeColor font-bold'>${item.price}</span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className='flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative'>
            <div onClick={() => setShowUser(!showUser)} className='flex'>
              <FaUser />
              <FaCaretDown />
            </div>
            {showUser && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className='absolute top-6 left-0 z-50 bg-primeColor w-44 text-[#767676] h-auto p-4 pb-6'
              >
                <Link to='/signin'>
                  <li className='text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer'>
                    Login
                  </li>
                </Link>
                <Link onClick={() => setShowUser(false)} to='/signup'>
                  <li className='text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer'>
                    Sign Up
                  </li>
                </Link>
                <li
                  onClick={() => {
                    window.location.href = "/profile";
                  }}
                  className='text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer'
                >
                  Profile
                </li>
                {/* <li className='text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer'>
                  Others
                </li> */}
              </motion.ul>
            )}
            <Link to='/cart'>
              <div className='relative'>
                <FaShoppingCart />
                <span className='absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white'>
                  {products.length > 0 ? products.length : 0}
                </span>
              </div>
            </Link>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default HeaderBottom;
