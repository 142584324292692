import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: [],
  products: [],

  allProductList: [],
  originalState: [],
  filters: {
    priceRange: null, // Selected price range (e.g., { priceOne: 0, priceTwo: 100 })
    category: null, // Selected category (e.g., "clothes")
    brand: null,
  },
};

export const orebiSlice = createSlice({
  name: "orebi",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = state.products.find((item) => item._id === action.payload._id);
      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.products.push(action.payload);
      }
    },
    increaseQuantity: (state, action) => {
      const item = state.products.find((item) => item._id === action.payload._id);
      if (item) {
        item.quantity++;
      }
    },
    drecreaseQuantity: (state, action) => {
      const item = state.products.find((item) => item._id === action.payload._id);
      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
      }
    },
    deleteItem: (state, action) => {
      state.products = state.products.filter((item) => item._id !== action.payload);
    },
    setAllProducts: (state, action) => {
      state.allProductList = action.payload;
      state.originalState = action.payload;
    },

    setFilters: (state, action) => {
      const { priceRange, brand, category } = action.payload;

      // Update the filter states
      if (priceRange) {
        state.filters.priceRange = {
          priceOne: action.payload?.priceRang?.priceOne,
          priceTwo: action.payload?.priceRang?.priceTwo,
        };
      }
      if (brand) {
        state.filters.category = brand;
      }
      if (category) {
        state.filters.brand = category;
      }
      // Start with the original product list
      let filteredProducts = state.originalState;

      // Apply the price filter, if priceRange exists
      if (priceRange) {
        filteredProducts = filteredProducts.filter(
          (product) => product.price >= priceRange.priceOne && product.price <= priceRange.priceTwo
        );
      }

      // Apply the brand filter, if brand exists
      if (brand) {
        filteredProducts = filteredProducts.filter((product) => product.category === brand);
      }
      // Apply the brand filter, if brand exists
      if (category) {
        filteredProducts = filteredProducts.filter((product) => product.brand === category);
      }

      // Update the state with the filtered product list
      state.allProductList = filteredProducts;
    },

    // Action to reset filters
    resetFilters: (state) => {
      state.filters = { priceRange: null, category: null, brand: null };
      state.allProductList = state.originalState; // Reset to original list
    },
    resetCart: (state) => {
      state.products = [];
    },
  },
});

export const { addToCart, increaseQuantity, drecreaseQuantity, deleteItem, resetCart, setAllProducts, setFilters, resetFilters } =
  orebiSlice.actions;
export default orebiSlice.reducer;
