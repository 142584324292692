import React, { useEffect, useState } from "react";
import { BsGridFill } from "react-icons/bs";
import { ImList } from "react-icons/im";
import { GoTriangleDown } from "react-icons/go";
import { Button, Select } from "antd";
import { useDispatch } from "react-redux";
import { resetFilters, setFilters } from "../../../redux/orebiSlice";

const ProductBanner = ({ itemsPerPageFromBanner }) => {
  const [selected, setSelected] = useState("All");
  const dispatch = useDispatch();
  const handlePriceChange = (brand) => {
    setSelected(brand);
    dispatch(setFilters({ brand }));
  };
  const [girdViewActive, setGridViewActive] = useState(true);
  const [listViewActive, setListViewActive] = useState(false);
  useEffect(() => {
    const gridView = document.querySelector(".gridView");
    const listView = document.querySelector(".listView");

    gridView.addEventListener("click", () => {
      setListViewActive(false);
      setGridViewActive(true);
    });
    listView.addEventListener("click", () => {
      setGridViewActive(false);
      setListViewActive(true);
    });
  }, [girdViewActive, listViewActive]);

  return (
    <div className='w-full flex flex-col md:flex-row md:items-center justify-between'>
      {/* =========================================================
                            Left Part Start here
        ======================================================== */}

      <div className='flex items-center gap-4 d-none opacity-0'>
        <span
          className={`${
            girdViewActive ? "bg-primeColor text-white" : "border-[1px] border-gray-300 text-[#737373]"
          } w-8 h-8 text-lg flex items-center justify-center cursor-pointer gridView`}
        >
          <BsGridFill />
        </span>
        <span
          className={`${
            listViewActive ? "bg-primeColor text-white" : "border-[1px] border-gray-300 text-[#737373]"
          } w-8 h-8 text-base flex items-center justify-center cursor-pointer listView`}
        >
          <ImList />
        </span>
      </div>
      {/* =========================================================
                            Left Part End here
        ======================================================== */}
      {/* =========================================================
                            Right Part STart here
        ======================================================== */}
      <div className='flex items-center gap-2 md:gap-6 mt-4 md:mt-0'>
        <Button
          onClick={() => {
            dispatch(resetFilters());
          }}
          className='w-[100px] bg-green-500'
          variant='filled'
          type='default'
        >
          Reset Filters
        </Button>
        <div className='flex items-center gap-2 text-base text-[#767676] relative'>
          <label className='block'>Sort by category:</label>

          <Select
            label='Category'
            placeholder='Select Category'
            value={selected}
            onChange={(val) => handlePriceChange(val)}
            options={[
              { value: "", label: "All" },
              { value: "clothes", label: "Clothes" },
              { value: "cosmatics", label: "Cosmatics" },
              { value: "shoes", label: "shoes" },
              { value: "new_arrival", label: "New arrival" },
              { value: "special_offers", label: "Special offers" },
              { value: "best_sellers", label: "Best sellers" },
              { value: "productOfYear", label: "Product of the year" },
              { value: "bannerImages", label: "Banner Images" },
            ]}
            mb='lg'
            className='w-[200px]'
            suffixIcon={<GoTriangleDown />}
          />
        </div>
      </div>
      {/* =========================================================
                            Right Part End here
        ======================================================== */}
    </div>
  );
};

export default ProductBanner;
