import React, { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { logoLight } from "../../assets/images";
import api from "../../utils/axiosInstance";
import toast from "react-hot-toast";
const SignIn = () => {
  const navigate = useNavigate();
  // ============= Initial State Start here =============
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signLoader, setSendLoader] = useState(false);
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");

  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  // ============= Event Handler Start here =============
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };
  // ============= Event Handler End here ===============
  const handleSignUp = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setErrEmail(""); // Reset error states
    setErrPassword("");

    if (!email) {
      setErrEmail("Enter your email");
      return; // Stop further execution
    }

    if (!password) {
      setErrPassword("Create a password");
      return; // Stop further execution
    }

    try {
      setSendLoader(true);

      const objectSend = { email, password };
      const { token, user } = await api.post("/user/login", objectSend);

      localStorage.setItem("token", token);
      localStorage.setItem("user_data", JSON.stringify(user));
      navigate("/");

      setEmail(""); // Clear inputs
      setPassword("");
    } catch (error) {
      toast.error("Error during sign in. try again with correct password and email");
      console.error("Error during sign in:", error);
    } finally {
      setSendLoader(false); // Ensure loader is stopped
    }
  };
  console.log(">>", {
    email,
    password,
  });
  return (
    <div className='w-full h-screen flex items-center justify-center'>
      <div className='w-full lgl:w-1/2 h-full'>
        {successMsg ? (
          <div className='w-full h-full flex flex-col justify-center'>
            <p className='w-full px-4 py-10 text-green-500 font-medium font-titleFont'>{successMsg}</p>
            <Link to='/signup'>
              <button
                className='w-full h-10 bg-primeColor text-gray-200 rounded-md text-base font-titleFont font-semibold 
            tracking-wide hover:bg-black hover:text-white duration-300'
              >
                Sign Up
              </button>
            </Link>
          </div>
        ) : (
          <form onSubmit={handleSignUp} className='w-full lg:w-[450px] m-auto h-screen flex items-center justify-center'>
            <div className='px-6 py-4 w-full h-[90%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor'>
              <h1 className='font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4'>
                Sign in
              </h1>
              <div className='flex flex-col gap-3'>
                {/* Email */}
                <div className='flex flex-col gap-.5'>
                  <input
                    onChange={handleEmail}
                    value={email}
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='email'
                    placeholder='john@workemail.com'
                  />
                  {errEmail && (
                    <p className='text-sm text-red-500 font-titleFont font-semibold px-4'>
                      <span className='font-bold italic mr-1'>!</span>
                      {errEmail}
                    </p>
                  )}
                </div>

                {/* Password */}
                <div className='flex flex-col gap-.5'>
                  <p className='font-titleFont text-base font-semibold text-gray-600'>Password</p>
                  <input
                    onChange={handlePassword}
                    value={password}
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='password'
                    placeholder='Create password'
                  />
                  {errPassword && (
                    <p className='text-sm text-red-500 font-titleFont font-semibold px-4'>
                      <span className='font-bold italic mr-1'>!</span>
                      {errPassword}
                    </p>
                  )}
                </div>
                <div className='flex gap-6 my-[10px]'>
                  <button
                    onClick={() => {
                      navigate("/");
                    }}
                    className='bg-green-500  text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300'
                  >
                    Shop
                  </button>
                  <button
                    type='submit'
                    disabled={signLoader}
                    onClick={handleSignUp}
                    className='bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300'
                  >
                    Sign In
                  </button>
                </div>
                <p className='text-sm text-center font-titleFont font-medium'>
                  Don't have an Account?{" "}
                  <Link to='/signup'>
                    <span className='hover:text-blue-600 duration-300'>Sign up</span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignIn;
