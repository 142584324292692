import React, { useEffect, useState } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement, Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Breadcrumbs } from "@mantine/core";
import { Button } from "antd"; // Import Ant Design's Button component
import api from "../../utils/axiosInstance";
import { initializeUseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { resetCart } from "../../redux/orebiSlice";
const CheckoutForm = () => {
  const products = useSelector((state) => state.orebiReducer.products);
  const [totalAmt, setTotalAmt] = useState("");
  const [shippingCharge, setShippingCharge] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    let price = 0;
    products?.map((item) => {
      price += item.price * item.quantity;
      return price;
    });
    setTotalAmt(price);
  }, [products]);
  useEffect(() => {
    if (totalAmt <= 200) {
      setShippingCharge(30);
    } else if (totalAmt <= 400) {
      setShippingCharge(25);
    } else if (totalAmt > 401) {
      setShippingCharge(20);
    }
  }, [totalAmt]);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const userInfo = JSON.parse(localStorage.getItem("user_data"));

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (elements == null) {
        return;
      }
      const cardElement = elements.getElement(CardElement);
      // Trigger form validation and wallet collection

      if (!cardElement) {
        setErrorMessage("Card element not found");
        return;
      }
      toast.success("Payment in process...");

      // Create the PaymentIntent and obtain clientSecret from your server endpoint
      const { clientSecret } = await api.post("/create-payment-intent", { amount: totalAmt + shippingCharge });

      // Confirm the payment with the CardElement
      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement, // Pass the CardElement instead of elements
          billing_details: {
            name: userInfo?.name, // Add name
            email: userInfo?.email, // Add name
            phone: userInfo?.email, // Add name
          },
        },
      });

      if (response.paymentIntent?.status === "succeeded") {
        toast.success("Payment successful");

        setTimeout(() => {
          window.location.href = "/";
        }, 4000);
        // dispatch(resetCart());
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // ===========
  // ========================

  return (
    <div className='my-10 max-w-lg mx-auto p-6 bg-white shadow-lg rounded-lg border border-gray-200'>
      <Breadcrumbs title='Payment Gateway' />

      <div className='text-center mb-6'>
        <h3 className='text-2xl font-semibold text-gray-800'>Complete Your Payment</h3>
        <p className='mt-2 text-lg text-gray-600'>
          To confirm your order, please proceed with the payment below. The total amount due is displayed above.
        </p>
      </div>

      <div className='space-y-3'>
        <p className='flex items-center justify-between border-b border-gray-400 py-2 text-lg font-medium'>
          Subtotal
          <span className='font-semibold text-xl'>{totalAmt} PKR</span>
        </p>
        <p className='flex items-center justify-between border-b border-gray-400 py-2 text-lg font-medium'>
          Shipping Charge
          <span className='font-semibold text-xl'>{shippingCharge} PKR</span>
        </p>
        <p className='flex items-center justify-between py-3 text-lg font-medium border-b border-gray-400'>
          Total
          <span className='font-bold text-2xl text-primaryColor'>{totalAmt + shippingCharge} PKR</span>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='mt-6 space-y-4'>
          <div className='bg-gray-50 p-4 rounded-lg border border-gray-300'>
            <label htmlFor='card-element' className='block text-sm font-medium text-gray-700'>
              Credit or Debit Card
            </label>
            <CardElement className='w-full p-3 mt-2 border rounded-md shadow-sm' />
          </div>

          {/* Error Message */}
          {errorMessage && <div className='text-red-500 mt-2 text-sm'>{errorMessage}</div>}

          {/* Pay Now Button */}
          <Button
            type='primary'
            htmlType='submit'
            block
            disabled={!stripe || loading}
            className='mt-6 py-3 bg-green-500 hover:bg-green-700 text-white disabled:bg-gray-400 disabled:cursor-not-allowed rounded-lg'
          >
            {loading ? "Processing..." : "Pay Now"}
          </Button>
        </div>
      </form>
    </div>
  );
};

const stripePromise = loadStripe(
  "pk_test_51NXT6EG9JhNDU81sRgJNIV6RScMl20N5ObMugzeP2syYoIyPW44c1QENhWcf4EMHQpB6PjPqWuqYi3qPAUIxJPBN00snIjLqWc"
);

const App = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default App;
