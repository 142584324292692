import React, { useState, useEffect } from "react";
import { Modal, TextInput, Textarea, NumberInput, Button, Group, Select, Radio } from "@mantine/core";
import api from "../../utils/axiosInstance";

import ImageUploading from "react-images-uploading";
const AddProductModal = ({ isOpen, onClose, productData, onSuccess, editModal }) => {
  const [images, setImages] = useState([]);
  const [loader, setloader] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    price: 0,
    description: "",
    category: "N/A",
    status: "available",
    rating: 0,
    availability: true,
    image: "",
    target_audience: "",
    color: "",
    badge: false,
  });

  // When modal opens, pre-fill values if productData is provided
  useEffect(() => {
    if (productData) {
      setFormValues({
        name: productData.name || "",
        price: productData.price || 0,
        description: productData.description || "",
        category: productData.category || "N/A",
        status: productData.status || "available",
        rating: productData.rating || 0,
        availability: productData.availability !== undefined ? productData.availability : true,
        image: productData.image || "",
        target_audience: productData.target_audience || "",
        color: productData.color || "",
        file: productData.file || "",
        brand: productData.brand || "",
      });
    }
  }, [productData, isOpen]);

  // Update form values dynamically
  const updateField = (field, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create a FormData object
    // Convert badge string to boolean
    const badgeBoolean = formValues.badge === "true";
    const formData = new FormData();

    // Append all form values except 'file' and 'badge' (which is separately handled)
    for (const key in formValues) {
      if (key !== "badge" && key !== "color") {
        formData.append(key, formValues[key]);
      }
    }

    // Append badge as a boolean
    formData.append("badge", badgeBoolean);

    // Append color as a string (ensure it's not an array)
    if (typeof formValues.color === "string") {
      formData.append("color", formValues.color);
    }

    if (images.length > 0 && images[0]?.file) {
      formData.append("file", images[0].file);
    } else {
      console.error("No image selected or file is missing");
    }

    try {
      setloader(true);
      // Perform the API request to add the product
      if (editModal) {
        await api.patch(`/product/update/${productData.id}`, formData); // Adjust the API endpoint as needed
      } else {
        await api.post("/product/add", formData); // Adjust the API endpoint as needed
      }
      setloader(false);

      onSuccess();
      // onClose(); // Close modal after successful submission
    } catch (error) {
      setloader(false);
      let keys = Object.keys(error?.response?.data.errors);

      alert(error?.response?.data?.errors[keys[0]] || "something went wrong");

      // Optionally, handle error (e.g., show an error message to the user)
    }
  };

  return (
    <Modal size={"lg"} opened={isOpen} onClose={onClose} title='Add New Product' centered>
      {/* Modal Content */}
      <form onSubmit={handleSubmit}>
        {/* Product Name Field */}
        <TextInput
          label='Product Name'
          placeholder='Enter product name'
          value={formValues.name}
          onChange={(e) => updateField("name", e.target.value)}
          required
          mb='md'
        />
        <TextInput
          label='Product Brand'
          placeholder='Enter brand name'
          value={formValues.brand}
          onChange={(e) => updateField("brand", e.target.value)}
          required
          mb='md'
        />
        {/* Price Field */}
        <NumberInput
          label='Price'
          placeholder='Enter product price'
          value={formValues.price}
          onChange={(val) => updateField("price", val)}
          required
          mb='md'
          min={1}
          precision={2}
        />
        {/* Description Field */}
        <Textarea
          label='Description'
          placeholder='Enter product description'
          value={formValues.description}
          onChange={(e) => updateField("description", e.target.value)}
          required
          mb='md'
        />
        {/* Category Field */}
        <Select
          label='Category'
          placeholder='Select target audience '
          value={formValues.category}
          onChange={(val) => updateField("category", val)}
          data={[
            { value: "clothes", label: "Clothes" },

            { value: "cosmatics", label: "Cosmatics" },
            { value: "shoes", label: "shoes" },
            { value: "new_arrival", label: "New arrival" },
            { value: "special_offers", label: "Special offers" },
            { value: "best_sellers", label: "Best sellers" },
            { value: "productOfYear", label: "Product of the year" },
            { value: "bannerImages", label: "Banner Images" },
          ]}
          mb='md'
        />
        {/* Status Field */}
        <Select
          label='Status'
          placeholder='Select product status'
          value={formValues.status}
          onChange={(val) => updateField("status", val)}
          data={[
            { value: "available", label: "Available" },
            { value: "unavailable", label: "Unavailable" },
          ]}
          mb='md'
        />
        <Select
          label='Target Audiece'
          placeholder='Select product status'
          value={formValues.target_audience}
          onChange={(val) => updateField("target_audience", val)}
          data={[
            { value: "men", label: "Men" },

            { value: "women", label: "Women" },
          ]}
          mb='md'
        />
        {/* Rating Field */}
        <NumberInput
          label='Rating'
          placeholder='Enter product rating'
          value={formValues.rating}
          onChange={(val) => updateField("rating", val)}
          min={0}
          max={5}
          mb='md'
        />
        {/* Availability Field */}

        <Radio.Group
          value={formValues.badge}
          onChange={(value) => updateField("badge", value)} // Directly use the value
          name='booleanSelection'
          label='Is this New or old'
        >
          <Group mt='xs'>
            <Radio value='true' label='True' />
            <Radio value='false' label='False' />
          </Group>
        </Radio.Group>

        <TextInput
          label='Color'
          className='mt-4'
          placeholder='Enter Color'
          value={formValues.color}
          onChange={(e) => updateField("color", e.target.value)}
          mb='md'
        />
        {/* Image URL Field */}

        {/* <TextInput
          label='Image URL'
          placeholder='Enter product image URL'
          value={formValues.image}
          onChange={(e) => updateField("image", e.target.value)}
          mb='md'
        /> */}

        <UploaderCompoennt image={images} setImage={setImages} />
        {/* Buttons */}
        <Group position='right' mt='md'>
          <Button variant='outline' color='gray' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' color='blue' loading={loader}>
            {productData?.name ? "Update Product" : "Add Product"}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default AddProductModal;
function UploaderCompoennt({ image, setImage }) {
  const maxNumber = 1; // Allow only one image
  const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
  const acceptedFormats = ["image/jpeg", "image/jpg", "image/png", "image/webp"];

  const onChange = (imageList) => {
    const file = imageList[0]?.file;

    if (file) {
      // Check file size
      if (file.size > maxFileSize) {
        alert("File size exceeds the 10MB limit. Please upload a smaller file.");
        return;
      }

      // Check file type
      if (!acceptedFormats.includes(file.type)) {
        alert("Invalid file format. Please upload a .jpeg, .jpg, or .png file.");
        return;
      }
    }

    setImage(imageList);
  };

  const handleRemove = () => {
    setImage([]); // Clear the image list
  };

  return (
    <div className='my-5 border p-4'>
      <ImageUploading multiple={false} value={image} onChange={onChange} maxNumber={maxNumber} dataURLKey='data_url'>
        {({ imageList, onImageUpload }) => (
          <div>
            {!image.length && (
              <button
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  onImageUpload();
                }}
              >
                Upload Image
              </button>
            )}
            {imageList.map((image, index) => (
              <div key={index}>
                <img src={image.data_url} alt='Uploaded' width='150' height='70' />

                <Button variant='outline' className='my-3' color='red' onClick={handleRemove}>
                  Remove Image
                </Button>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
