import React from "react";
import NavTitle from "./NavTitle";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../../../redux/orebiSlice";

const Price = () => {
  const dispatch = useDispatch();

  const priceList = [
    {
      _id: 951,
      priceOne: 0,
      priceTwo: 100,
    },
    {
      _id: 952,
      priceOne: 150,
      priceTwo: 500,
    },
    {
      _id: 953,
      priceOne: 600,
      priceTwo: 2000,
    },
    {
      _id: 954,
      priceOne: 4000,
      priceTwo: 8000,
    },
    {
      _id: 955,
      priceOne: 9000,
      priceTwo: 20000,
    },
  ];
  const handlePriceChange = (priceRange) => {
    dispatch(setFilters({ priceRange }));
  };
  return (
    <div className='cursor-pointer'>
      <NavTitle title='Shop by Price' icons={false} />
      <div className='font-titleFont'>
        <ul className='flex flex-col gap-4 text-sm lg:text-base text-[#767676]'>
          {priceList.map((item) => (
            <li
              key={item._id}
              onClick={() => handlePriceChange(item)}
              className='border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300'
            >
              {item.priceOne.toFixed(2)} - {item.priceTwo.toFixed(2)} PKR
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Price;
