import React, { useEffect, useState } from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { bestSellerOne, bestSellerTwo, bestSellerThree, bestSellerFour } from "../../../assets/images/index";
import api from "../../../utils/axiosInstance";

const BestSellers = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    fetchProducts();
  }, []); // Empty dependency array to run the effect once on mount
  const fetchProducts = async () => {
    try {
      const response = await api.get("/product/list?category=best_sellers"); // Adjust the API endpoint as needed
      setProducts(response.data); // Store the fetched products in the state
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error accordingly, maybe set an error state
    }
  };
  return (
    <div className='w-full pb-20'>
      <Heading heading='Our Bestsellers' />
      <div className='w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10'>
        {products.map((items, index) => {
          return (
            <div className='px-2' key={index}>
              <Product
                _id='100001'
                id={items.id}
                img={items.image}
                image={items.image}
                productName={items.name}
                name={items.name}
                price={items.price}
                color={items.color}
                badge={items.badge}
                des={items.description}
              />
            </div>
          );
        })}
        {/* <Product
          _id='1011'
          img={bestSellerOne}
          productName='Flower Base'
          price='35.00'
          color='Blank and White'
          badge={true}
          des='Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.'
        />
        <Product
          _id='1012'
          img={bestSellerTwo}
          productName='New Backpack'
          price='180.00'
          color='Gray'
          badge={false}
          des='Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.'
        />
        <Product
          _id='1013'
          img={bestSellerThree}
          productName='Household materials'
          price='25.00'
          color='Mixed'
          badge={true}
          des='Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.'
        />
        <Product
          _id='1014'
          img={bestSellerFour}
          productName='Travel Bag'
          price='220.00'
          color='Black'
          badge={false}
          des='Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.'
        /> */}
      </div>
    </div>
  );
};

export default BestSellers;
