// src/api/axios.js

import axios from "axios";

// You can set up a base URL here for all API calls
const BASE_URL = "https://secure-forest-66688-ae9e45e1a076.herokuapp.com/api/v1/"; // Replace with your API URL

const instance = axios.create({
  baseURL: BASE_URL,
});

// Request interceptor for adding tokens or logging request information
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Replace with your token retrieval logic
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling errors globally
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // You can add your error handling logic here
    if (error.response && error.response.status === 401) {
      // Handle Unauthorized error or redirect to login
      window.location.href = "/";
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
    return Promise.reject(error);
  }
);

const api = {
  get: async (url, config = {}) => {
    try {
      const response = await instance.get(url, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  post: async (url, data, config = {}) => {
    try {
      const response = await instance.post(url, data, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  put: async (url, data, config = {}) => {
    try {
      const response = await instance.put(url, data, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  patch: async (url, data, config = {}) => {
    try {
      const response = await instance.patch(url, data, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (url, config = {}) => {
    try {
      const response = await instance.delete(url, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default api;
