import React from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { GiReturnArrow } from "react-icons/gi";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";

const Product = (props) => {
  const dispatch = useDispatch();
  const _id = props.productName;
  const idString = (_id) => {
    return String(_id).toLowerCase().split(" ").join("");
  };
  const rootId = idString(_id);

  const navigate = useNavigate();
  const productItem = props;
  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: {
        item: productItem,
      },
    });
  };
  return (
    <div className='w-full max-w-sm mx-auto relative group border border-gray-200 rounded-lg shadow-sm overflow-hidden'>
      {/* Image Section */}
      <div className='h-64 relative'>
        <img className='w-full h-full object-contain' src={props.img} alt={props.name} />
        {props.badge && <div className='absolute top-4 left-4 bg-red-500 text-white text-xs px-2 py-1 rounded-lg'>New</div>}
      </div>

      {/* Hover Overlay */}
      <div className='w-full h-32 absolute bg-white -bottom-32 group-hover:bottom-0 transition-all duration-500'>
        <ul className='flex flex-col items-end justify-center gap-2 px-4 border-l border-r'>
          <li
            onClick={() =>
              dispatch(
                addToCart({
                  _id: props.id,
                  name: props.name,
                  quantity: 1,
                  image: props.image,
                  badge: props.badge,
                  price: props.price,
                  colors: props.color,
                })
              )
            }
            className='text-gray-600 hover:text-primeColor text-sm flex items-center gap-2 cursor-pointer border-b border-gray-200 pb-2'
          >
            Add to Cart <FaShoppingCart />
          </li>
          <li
            onClick={handleProductDetails}
            className='text-gray-600 hover:text-primeColor text-sm flex items-center gap-2 cursor-pointer pb-2'
          >
            View Details <MdOutlineLabelImportant className='text-lg' />
          </li>
        </ul>
      </div>

      {/* Product Info */}
      <div className='p-4 bg-white'>
        <div className='flex justify-between items-center'>
          <h2 className='text-lg font-bold text-primeColor'>
            {props?.name?.length > 30 ? `${props.name?.slice(0, 30)}...` : props?.name}
          </h2>

          <p className='text-sm text-gray-500'>{props.price} PKR</p>
        </div>
        {/* <p className='text-sm text-gray-500 mt-1'>{props.color}</p> */}
      </div>
    </div>
  );
};

export default Product;
