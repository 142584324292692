import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { newArrOne, newArrTwo, newArrThree, newArrFour } from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import api from "../../../utils/axiosInstance";

const NewArrivals = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    fetchProducts();
  }, []); // Empty dependency array to run the effect once on mount
  const fetchProducts = async () => {
    try {
      const response = await api.get("/product/list?category=new_arrival"); // Adjust the API endpoint as needed
      setProducts(response.data); // Store the fetched products in the state
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error accordingly, maybe set an error state
    }
  };

  const settings = {
    infinite: products.length > 4, // Disable infinite if fewer than 4 items
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className='w-full pb-16'>
      <Heading heading='New Arrivals' />
      <Slider {...settings}>
        {products.map((items, index) => {
          return (
            <div className='px-2' key={index}>
              <Product
                _id='100001'
                id={items.id}
                img={items.image}
                image={items.image}
                productName={items.name}
                name={items.name}
                price={items.price}
                color={items.color}
                badge={items.badge}
                des={items.description}
              />
            </div>
          );
        })}

        {/* <div className='px-2'>
          <Product
            _id='100002'
            img={newArrTwo}
            productName='Smart Watch'
            price='250.00'
            color='Black'
            badge={true}
            des='Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.'
          />
        </div>
        <div className='px-2'>
          <Product
            _id='100003'
            img={newArrThree}
            productName='cloth Basket'
            price='80.00'
            color='Mixed'
            badge={true}
            des='Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.'
          />
        </div> */}
        {/* <div className='px-2'>
          <Product
            _id='100004'
            img={newArrFour}
            productName='Funny toys for babies'
            price='60.00'
            color='Mixed'
            badge={false}
            des='Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.'
          />
        </div>
        <div className='px-2'>
          <Product
            _id='100005'
            img={newArrTwo}
            productName='Funny toys for babies'
            price='60.00'
            color='Mixed'
            badge={false}
            des='Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.'
          />
        </div> */}
      </Slider>
    </div>
  );
};

export default NewArrivals;
