import React, { useEffect, useState } from "react";
import AddProductModal from "./addproductmodal";
import ViewUsers from "./viewusers";
import ViewOrders from "./viewOrders";
import ProductList from "./viewProducts";
import Transaction from "./viewtransctions";
import { Col, Row } from "antd";
import { Button } from "@mantine/core";
import { useStateHistory } from "@mantine/hooks";

const AdminPage = () => {
  // Check if token and user exist in localStorage
  const token = localStorage.getItem("token");

  const user = localStorage.getItem("user");
  const history = useStateHistory(); // Hook for navigation
  useEffect(() => {
    if (token === undefined || !user) {
      history.push("/"); // Redirect to home
    }
  }, []);

  const [isModalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("productList"); // Default tab

  const closeModal = () => setModalOpen(false);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className='min-h-screen h-screen overflow-hidden bg-gray-100'>
      {/* Add Product Modal */}
      <AddProductModal isOpen={isModalOpen} onClose={closeModal} />

      {/* Sidebar */}
      <div className='text-2xl font-semibold text-center py-4'>Admin Panel</div>
      <Row className='h-[calc(100vh-4rem)]'>
        {/* Sidebar */}
        <Col lg={3} className='h-full'>
          <div className='h-full bg-gray-200 text-gray-800 flex flex-col p-6'>
            {/* Sidebar Menu */}
            <nav>
              <ul>
                <li className='my-4'>
                  <button
                    onClick={() => handleTabClick("productList")}
                    className={`w-full py-2 px-4 text-left ${
                      activeTab === "productList" ? "bg-gray-400" : "bg-gray-300"
                    } rounded-md`}
                  >
                    Product List
                  </button>
                </li>
                <li className='my-4'>
                  <button
                    onClick={() => handleTabClick("viewOrders")}
                    className={`w-full py-2 px-4 text-left ${
                      activeTab === "viewOrders" ? "bg-gray-400" : "bg-gray-300"
                    } rounded-md`}
                  >
                    View Orders
                  </button>
                </li>
                <li className='my-4'>
                  <button
                    onClick={() => handleTabClick("viewUsers")}
                    className={`w-full py-2 px-4 text-left ${
                      activeTab === "viewUsers" ? "bg-gray-400" : "bg-gray-300"
                    } rounded-md`}
                  >
                    View Users
                  </button>
                </li>
                <li className='my-4'>
                  <button
                    onClick={() => handleTabClick("transactions")}
                    className={`w-full py-2 px-4 text-left ${
                      activeTab === "transactions" ? "bg-gray-400" : "bg-gray-300"
                    } rounded-md`}
                  >
                    Users Transactions
                  </button>
                </li>
                <li className='mt-10'>
                  <Button variant='filled' onClick={() => (window.location.href = "/")}>
                    Go Back To website
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        </Col>

        {/* Main Content */}
        <Col lg={21} className='h-full'>
          <div className='h-full overflow-auto p-6'>
            {/* Main Content */}
            <div className='flex-1'>
              {activeTab === "productList" && <ProductList />}
              {activeTab === "viewOrders" && <ViewOrders />}
              {activeTab === "viewUsers" && <ViewUsers />}
              {activeTab === "transactions" && <Transaction />}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdminPage;
