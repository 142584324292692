import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const ProfilePage = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    oldPassword: "passabc12",
    password: "passabc12",
    confirmPassword: "passabc12",
    phone_number: "",
    role: undefined,
  });

  const navigate = useNavigate(); // or useNavigate for React Router v6

  useEffect(() => {
    try {
      // Try to get user data from localStorage
      const localSt = localStorage.getItem("user_data");

      if (localSt) {
        // Parse the data safely with try-catch block
        const parsedData = JSON.parse(localSt);

        // Destructure the user data and ensure fallback if any field is missing
        const { email, name, phone_number, role } = parsedData;

        // Update state with parsed values
        setUserData({
          name: name || "", // Fallback to empty string if name is not available
          email: email || "", // Fallback to empty string if email is not available
          oldPassword: "passabc12", // Default old password
          password: "passabc12", // Default password
          confirmPassword: "passabc12", // Default confirm password
          phone_number: phone_number || "", // Fallback to empty string if phone_number is not available
          role: role,
        });
      } else {
        console.warn("No user data found in localStorage.");
        // Redirect to home page if user data is not found
        navigate("/"); // Use window.location.assign("/") for a non-react-router approach
      }
    } catch (error) {
      console.error("Error parsing user data from localStorage:", error);
      // Optionally, you could set default values in case of error
      setUserData({
        name: "",
        email: "",
        oldPassword: "passabc12",
        password: "passabc12",
        confirmPassword: "passabc12",
        phone_number: "",
      });
      // Redirect to home page in case of error
      navigate("/"); // Use window.location.assign("/") for a non-react-router approach
    }
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Toggle edit mode
  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  return (
    <div className='min-h-screen bg-gray-100 flex justify-center items-center'>
      <div className='bg-white shadow-lg rounded-lg w-full max-w-lg p-8'>
        {/* Avatar Section */}
        <div className='flex justify-center mb-6'>
          <img
            src='https://via.placeholder.com/150'
            alt='User Avatar'
            className='w-36 h-36 rounded-full object-cover border-4 border-blue-500'
          />
        </div>

        {/* User Info Section */}
        <div className='grid grid-cols-1 gap-y-4'>
          <h2 className='text-2xl font-semibold text-gray-800 text-center'>
            {isEditing ? (
              <input
                type='text'
                name='name'
                value={userData.name}
                onChange={handleInputChange}
                className='text-xl font-semibold text-gray-800 bg-transparent border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 w-full my-[5px]'
              />
            ) : (
              userData.name
            )}
          </h2>

          <p className='text-gray-600 text-sm text-center'>
            {isEditing ? (
              <input
                type='email'
                name='email'
                value={userData.email}
                onChange={handleInputChange}
                className='text-sm text-gray-600 bg-transparent border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 w-full my-[5px]'
              />
            ) : (
              userData.email
            )}
          </p>
          {userData.role === "admin" && (
            <button
              className='bg-green-500 text-white py-2 px-6 rounded-full hover:bg-blue-600 focus:outline-none'
              onClick={() => {
                window.location.href = "/admin-page"; // Replace '/admin-page' with the actual path
              }}
            >
              Go to Admin Panel
            </button>
          )}
          <div className='space-y-3'>
            <div className='my-[5px]'>
              <strong className='block text-gray-700'>Old Password:</strong>
              {isEditing ? (
                <input
                  type='password'
                  name='oldPassword'
                  value={userData.oldPassword}
                  onChange={handleInputChange}
                  className='text-gray-700 bg-transparent border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 w-full'
                />
              ) : (
                "******" // Mask password on view mode
              )}
            </div>

            <div className='my-[5px]'>
              <strong className='block text-gray-700'>New Password:</strong>
              {isEditing ? (
                <input
                  type='password'
                  name='password'
                  value={userData.password}
                  onChange={handleInputChange}
                  className='text-gray-700 bg-transparent border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 w-full'
                />
              ) : (
                "******" // Mask password on view mode
              )}
            </div>

            <div className='my-[5px]'>
              <strong className='block text-gray-700'>Confirm New Password:</strong>
              {isEditing ? (
                <input
                  type='password'
                  name='confirmPassword'
                  value={userData.confirmPassword}
                  onChange={handleInputChange}
                  className='text-gray-700 bg-transparent border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 w-full'
                />
              ) : (
                "******" // Mask password on view mode
              )}
            </div>
          </div>

          {/* Toggle Edit Button */}
          <div className='text-center mt-6'>
            <button
              onClick={() => {
                localStorage.clear(); // Clear all items in local storage
                window.location.reload(); // Refresh the page
              }}
              className='bg-red-500 text-white py-2 px-6 rounded-full hover:bg-blue-600 focus:outline-none'
            >
              Logout
            </button>

            <Link to={"/"} className='bg-blue-500 text-white py-2 px-6 rounded-full hover:bg-blue-600 focus:outline-none mx-3'>
              Go Back
            </Link>
            <button
              onClick={toggleEdit}
              className='bg-blue-500 text-white py-2 px-6 rounded-full hover:bg-blue-600 focus:outline-none'
            >
              {isEditing ? "Save" : "Edit Profile"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
