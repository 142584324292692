import React, { useEffect, useState } from "react";
import AddProductModal from "./addproductmodal";
import api from "../../utils/axiosInstance";
import { Table, Image, Tag } from "antd";
import { Button } from "@mantine/core";
const AdminPage = () => {
  const [products, setProducts] = useState([]);
  const [loader, setloader] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const [editProdict, setEidProdict] = useState(false);
  // Fetch products data on component mount
  useEffect(() => {
    fetchProducts();
  }, []); // Empty dependency array to run the effect once on mount
  const fetchProducts = async () => {
    try {
      setloader(true);
      const response = await api.get("/transactions-history"); // Adjust the API endpoint as needed
      setloader(false);

      setProducts(response); // Store the fetched products in the state
    } catch (error) {
      console.error("Error fetching products:", error);
      setloader(false);

      // Handle error accordingly, maybe set an error state
    }
  };

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, record) => `${record.currency.toUpperCase()} ${amount}`,
    },
    {
      title: "Date",
      dataIndex: "created",
      key: "created",
      render: (created) => new Date(created).toLocaleString(),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "succeeded" ? "green" : "red"}>{status === "succeeded" ? "Success" : "Not Successful"}</Tag>
      ),
    },
    {
      title: "Customer Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Card",
      dataIndex: "card",
      key: "card",
    },
    {
      title: "Owner Name",
      dataIndex: "owner_name",
      key: "owner_name",
    },
    {
      title: "Postal Code",
      dataIndex: "postal_code",
      key: "postal_code",
    },
  ];

  return (
    <div className='flex min-h-screen bg-gray-100'>
      {/* Main Content */}
      <div className='flex-1 p-[20px]'>
        <div className='bg-white rounded-lg shadow-md  p-[20px]'>
          <div className='mb-6 flex justify-between items-center'>
            <h1 className='text-3xl font-semibold'>Transactions History</h1>
          </div>

          {/* Table */}
          <div>
            <Table columns={columns} loading={loader} dataSource={products} rowKey='id' bordered pagination={{ pageSize: 5 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
