import React, { useEffect, useState } from "react";
import AddProductModal from "./addproductmodal";
import api from "../../utils/axiosInstance";
import { Table, Image } from "antd";
import { Button } from "@mantine/core";
import toast from "react-hot-toast";
const AdminPage = () => {
  const [products, setProducts] = useState([]);
  const [singleProduct, setSingleProduct] = useState({});
  const [loader, setLoader] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const [editProdict, setEidProdict] = useState(false);
  // Fetch products data on component mount
  useEffect(() => {
    fetchProducts();
  }, []); // Empty dependency array to run the effect once on mount
  const fetchProducts = async () => {
    try {
      setLoader(true);
      const response = await api.get("/product/list"); // Adjust the API endpoint as needed
      setProducts(response.data); // Store the fetched products in the state
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching products:", error);
      // Handle error accordingly, maybe set an error state
    }
  };

  const DeleteProdic = async (id) => {
    try {
      await api.delete(`/product/remove/${id}`); // Adjust the API endpoint as needed
      toast.success("product removed");
      fetchProducts();
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error accordingly, maybe set an error state
    }
  };
  const handleEdit = async (id) => {
    setSingleProduct(id);
    setEidProdict(true);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 150,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 150,
      render: (category) => (category ? category : "N/A"),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      width: 100,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      width: 100,
    },
    {
      title: "Badge",
      dataIndex: "badge",
      key: "badge",
      width: 100,
      render: (badge) => (badge ? "True" : "False"),
    },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
      width: 150,
      render: (availability) => (availability ? "In Stock" : "Out of Stock"),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 100,
      render: (image, record) => (
        <Image src={image} alt={record.name} width={50} height={50} style={{ objectFit: "cover", borderRadius: "4px" }} />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      render: (_, record) => (
        <>
          <Button variant='solid' type='primary' style={{ marginRight: "8px" }} onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type='danger' onClick={() => DeleteProdic(record.id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];
  return (
    <div className='flex min-h-screen bg-gray-100'>
      {/* Main Content */}
      <div className='flex-1 p-[20px]'>
        <div className='bg-white rounded-lg shadow-md  p-[20px]'>
          <div className='mb-6 flex justify-between items-center'>
            <h1 className='text-3xl font-semibold'>Product List</h1>
            <button
              onClick={() => {
                setModalOpen(true);
              }}
              className='px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700'
            >
              Add New Product
            </button>
          </div>

          {/* Table */}
          <div>
            <Table loading={loader} columns={columns} dataSource={products} rowKey='id' bordered pagination={{ pageSize: 5 }} />
          </div>
        </div>

        <AddProductModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSuccess={() => {
            setModalOpen(false);
            fetchProducts();
          }}
        />
        <AddProductModal
          isOpen={editProdict}
          onClose={() => setEidProdict(false)}
          productData={singleProduct}
          editModal={editProdict}
          onSuccess={() => {
            setEidProdict(false);
            setSingleProduct({});
            fetchProducts();
          }}
        />
      </div>
    </div>
  );
};

export default AdminPage;
