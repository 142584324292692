import React, { useEffect, useState } from "react";
import AddProductModal from "./addproductmodal";
import api from "../../utils/axiosInstance";
import { Table } from "antd";
import { Button } from "@mantine/core";

const AdminPage = () => {
  const [allUsers, setUsers] = useState([]);

  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => setModalOpen(false);
  useEffect(() => {
    fetchProducts();
  }, []); // Empty dependency array to run the effect once on mount
  const fetchProducts = async () => {
    try {
      const response = await api.get("/orders"); // Adjust the API endpoint as needed
      setUsers(response.data); // Store the fetched products in the state
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error accordingly, maybe set an error state
    }
  };

  const DeleteProdic = async (id) => {
    try {
      await api.delete(`/orders/${id}`); // Adjust the API endpoint as needed
      fetchProducts();
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error accordingly, maybe set an error state
    }
  };
  const handleEdit = async (id) => {
    // setSingleProduct(id);
    // setEidProdict(true);
  };
  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "User Name",
      dataIndex: "user.name",
      key: "userName",
      render: (text, record) => <span>{record?.user?.name}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (text) => <span>{parseFloat(text).toFixed(2)} PKR</span>, // Format as currency
    },
    {
      title: "Decline Reason",
      dataIndex: "declineReason",
      key: "totalPrice",
      render: (text) => <span>{text || "Not available"}</span>, // Format as currency
    },

    {
      title: "Order Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{new Date(text).toLocaleString()}</span>, // Format the date
    },
    {
      title: "Product Name",
      dataIndex: "orderItems",
      key: "productName",
      render: (orderItems) => <span>{orderItems.map((item) => item.product.name).join(", ")}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      render: (_, record) => (
        <>
          {/* <Button variant='solid' type='primary' style={{ marginRight: "8px" }} onClick={() => handleEdit(record)}>
            Edit
          </Button> */}
          <Button type='danger' onClick={() => DeleteProdic(record.id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];
  return (
    <div className='flex min-h-screen bg-gray-100'>
      {/* Add Product Modal */}
      {/* <AddProductModal isOpen={isModalOpen} onClose={closeModal} /> */}

      {/* Main Content */}
      <div className='flex-1 p-[20px]'>
        <div className='bg-white rounded-lg shadow-md  p-[20px]'>
          <div className='mb-6 flex justify-between items-center'>
            <h1 className='text-3xl font-semibold'>Orders List</h1>
          </div>

          {/* Table */}
          <div className='overflow-x-auto'>
            <Table dataSource={allUsers} columns={columns} rowKey={(record) => record.id} bordered />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
