import React, { useEffect, useState } from "react";
import AddProductModal from "./addproductmodal";
import api from "../../utils/axiosInstance";
import { Table } from "antd";
import { Button } from "@mantine/core";

const AdminPage = () => {
  const [allUsers, setUsers] = useState([]);

  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => setModalOpen(false);
  useEffect(() => {
    fetchProducts();
  }, []); // Empty dependency array to run the effect once on mount
  const fetchProducts = async () => {
    try {
      const response = await api.get("/all-users"); // Adjust the API endpoint as needed
      setUsers(response.data); // Store the fetched products in the state
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error accordingly, maybe set an error state
    }
  };

  const DeleteProdic = async (id) => {
    try {
      await api.delete(`/users/delete-user/?id=${id}`); // Adjust the API endpoint as needed
      fetchProducts();
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error accordingly, maybe set an error state
    }
  };
  const handleEdit = async (id) => {
    // setSingleProduct(id);
    // setEidProdict(true);
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleString(), // Formatting the date
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => new Date(text).toLocaleString(), // Formatting the date
    },
    {
      title: "Addresses",
      key: "addresses",
      render: (_, record) =>
        record.Addresses.map((address, index) => (
          <div key={index}>
            <p>
              <strong>City:</strong> {address.city}
            </p>
            <p>
              <strong>Country:</strong> {address.country}
            </p>
            <p>
              <strong>Postal Code:</strong> {address.postal_code}
            </p>
            <p>
              <strong>Address:</strong> {address.address}
            </p>
          </div>
        )),
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      render: (_, record) => (
        <>
          {/* <Button variant='solid' type='primary' style={{ marginRight: "8px" }} onClick={() => handleEdit(record)}>
            Edit
          </Button> */}
          <Button className='bg-red-600' onClick={() => DeleteProdic(record.id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];
  return (
    <div className='flex min-h-screen bg-gray-100'>
      {/* Add Product Modal */}
      {/* <AddProductModal isOpen={isModalOpen} onClose={closeModal} /> */}

      {/* Main Content */}
      <div className='flex-1 p-[20px]'>
        <div className='bg-white rounded-lg shadow-md  p-[20px]'>
          <div className='mb-6 flex justify-between items-center'>
            <h1 className='text-3xl font-semibold'>Users List</h1>
            <button
              onClick={() => {
                setModalOpen(true);
              }}
              className='px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700'
            >
              Add New User
            </button>
          </div>

          {/* Table */}
          <div className='overflow-x-auto'>
            <Table dataSource={allUsers} columns={columns} rowKey={(record) => record.id} bordered />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
