import React, { useState } from "react";
import { motion } from "framer-motion";
import NavTitle from "./NavTitle";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../../../redux/orebiSlice";

const Brand = () => {
  const [showBrands, setShowBrands] = useState(true);
  const dispatch = useDispatch();

  const items = useSelector((state) => state.orebiReducer.originalState);
  // Filter and map the products with brand information (_id and title)
  // Map through the items and return objects with the brand's _id and title
  const brandDetails = items
    .filter((item) => item.brand) // Filter only items that have a brand
    .map((item) => ({
      _id: item.id, // Assuming item.brand is an object with _id
      title: item.brand, // Assuming item.brand is an object with title
    }))
    .filter(
      (
        value,
        index,
        self // Remove duplicates based on 'title'
      ) =>
        index ===
        self.findIndex(
          (t) =>
            t.title === value.title // Check if the brand title is already encountered
        )
    );
  const handlePriceChange = (category) => {
    console.log("s", category);
    dispatch(setFilters({ category }));
  };
  return (
    <div>
      <div onClick={() => setShowBrands(!showBrands)} className='cursor-pointer'>
        <NavTitle title='Shop by Brand' icons={true} />
      </div>
      {showBrands && (
        <motion.div initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }}>
          <ul className='flex flex-col gap-4 text-sm lg:text-base text-[#767676]'>
            {brandDetails?.map((item) => (
              <li
                key={item._id}
                onClick={() => handlePriceChange(item.title)}
                className='border-b-[1px] border-b-[#F0F0F0] cursor-pointer pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300'
              >
                {item.title}
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default Brand;
