import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { productOfTheYear } from "../../../assets/images";
import ShopNow from "../../designLayouts/buttons/ShopNow";
import Image from "../../designLayouts/Image";
import api from "../../../utils/axiosInstance";

const YearProduct = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    fetchProducts();
  }, []); // Empty dependency array to run the effect once on mount
  const fetchProducts = async () => {
    try {
      const response = await api.get("/product/list?category=productOfYear&latest=true"); // Adjust the API endpoint as needed
      setProducts(response.data); // Store the fetched products in the state
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error accordingly, maybe set an error state
    }
  };

  return (
    <Link to='/shop'>
      <div className='w-full h-auto sm:py-4 lg:h-80 mb-20 bg-[#f3f3f3] md:bg-transparent relative font-titleFont flex flex-col md:flex-row'>
        {/* Image Section */}
        <div className='w-full md:w-1/2 h-full'>
          <Image className='w-full h-full object-cover' imgSrc={products?.image || productOfTheYear} />
        </div>
        {/* Description Section */}
        <div className='w-full md:w-1/2 h-full px-4 md:px-8 flex flex-col items-center justify-center'>
          <h1 className='text-3xl font-semibold text-primeColor'>Product of The Year</h1>
          <p className='text-base font-normal text-primeColor max-w-[600px] mb-4'>
            {products?.description || "This is the best product of the year!"}
          </p>
          <ShopNow />
        </div>
      </div>
    </Link>
  );
};

export default YearProduct;
