import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
  Navigate,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Journal from "./pages/Journal/Journal";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/paymentv2";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import ProfilePage from "./pages/Account/profile";
import AdminPage from "./pages/adminPage";
import { decodetoken } from "./utils/functions";
import { useEffect } from "react";
import api from "./utils/axiosInstance";
import { setAllProducts } from "./redux/orebiSlice";
import { useDispatch } from "react-redux";

const Layout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchItms = async () => {
      try {
        const res = await api.get("/product/list");
        console.log("RES", res);

        dispatch(setAllProducts(res?.data));
      } catch (error) {}
    };
    fetchItms();
  }, []);

  return (
    <div>
      <Header />
      <HeaderBottom />
      <SpecialCase />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path='/' element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path='/shop' element={<Shop />}></Route>
        <Route path='/about' element={<About />}></Route>

        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/journal' element={<Journal />}></Route>
        {/* ==================== Header Navlink End here ===================== */}
        <Route path='/offer' element={<Offer />}></Route>
        <Route path='/product/:_id' element={<ProductDetails />}></Route>
        <Route path='/cart' element={<Cart />}></Route>
        <Route path='/paymentgateway' element={<Payment />}></Route>
      </Route>
      <Route path='/signup' element={<SignUp />}></Route>
      <Route path='/signin' element={<SignIn />}></Route>
      <Route path='/profile' element={<ProfilePage />}></Route>
      <Route path='/profile' element={<ProfilePage />}></Route>
      <Route path='/admin-page' element={<AdminPage />}></Route>
    </Route>
  )
);

function App() {
  return (
    <div className='font-bodyFont'>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

const ProtectedRoute = () => {
  const user = decodetoken(); // Decode token to get the user information

  // Check if user is an admin
  if (user?.role !== "admin") {
    return <Navigate to='/' replace />; // Redirect to home if not an admin
  }

  return <Outlet />; // Render the nested routes (AdminPage) if admin
};
